import styled, { keyframes } from "styled-components";

import { ContentRow } from "./common.style";
import { speaker_001, speaker_002 } from "../images";

const FooterInfoWrapper = styled.div`
    display: block;
    padding: 20px;
    color: #eee;
    background-color: #302a2c;
    box-shadow: 0px 2px 5px #111 inset;
`;

const Link = styled.a`
    color: #eee;
    text-decoration: none;

    &:hover {
        color: #fff;
        text-decoration: underline;
    }
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
        font-size: 1.2em;
        margin-bottom: 10px;

        &:hover {
            font-weight: 600;
        }
    }
`;

const CentreGrouping = styled(ContentRow)`
    max-width: 800px;
    margin: auto;
`;

const scroll = keyframes`
    0% {
        background-position: 50% 10%;
    }
    50% {
        background-position: 50% 80%;
    }
    100% {
        background-position: 50% 10%;
    }
`;

const AnimatedBackground = styled.div`
    display: block;
    height: 300px;
    background-image: url(${speaker_002});
    background-repeat: no-repeat;
    animation: ${scroll} 120s linear infinite;

    &:hover {
        animation-play-state: paused;
    }

    @media (max-width: 400px) {
        height: 200px;
    }
`;

export { FooterInfoWrapper, Link, List, CentreGrouping, AnimatedBackground };
