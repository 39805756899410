import * as Icons from "../../icons";

interface IconProps {
    name: string;
    [name: string]: any;
}
export const Icon = ({ name, ...props }: IconProps) => {
    const nProps = {
        style: {
            width: 30,
            height: 30,
            fill: "#ddd",
        },
        ...props,
    };

    return (
        name && (Icons as any)[name] ? (Icons as any)[name] : Icons.NotFound
    )(nProps);
};

export default Icon;
