import React from "react";

import Icon from "../components/common/Icon";
import Image from "../components/common/Image";

import {
    PageWrapper,
    Section,
    Center,
    ContentRow,
    ContentTile,
    Spacer,
} from "styles/common.style";
import {
    FooterInfoWrapper,
    Link,
    List,
    CentreGrouping,
    AnimatedBackground,
} from "styles/home.style";

const HomePage: React.FunctionComponent = () => {
    const pStyle = { margin: "0 20px 20px 20px" };

    return (
        <PageWrapper>
            <Section>
                <Center>
                    <Image
                        name="dntlogo"
                        alt="DNT Acoustics logo"
                        title="DNT Acoustics - loudspeaker design, restoration and upgrades"
                    />
                </Center>
            </Section>
            <Section borderless={true}>
                <AnimatedBackground />
            </Section>
            <Section>
                <CentreGrouping align="flex-start">
                    <ContentTile grid={50} justify="center">
                        <h2>Services</h2>
                        <List style={{ margin: "20px 0", textAlign: "center" }}>
                            <li>Custom loudspeaker design</li>
                            <li>Crossover upgrades and voicing </li>
                            <li>Speaker restoration</li>
                            <li>Speaker cabinets and refinishing</li>
                            <li>Custom cables</li>
                        </List>
                    </ContentTile>
                </CentreGrouping>
            </Section>
            <Section>
                <ContentRow style={{ maxWidth: 1200, margin: "auto" }}>
                    <ContentTile grid={50}>
                        <Center>
                            <p style={pStyle}>
                                <i>
                                    "...just thought I'd let you know that the
                                    speakers sound great...well matched with the
                                    amp...the first record I played actually
                                    brought me to tears. THANKS again for your
                                    work"
                                </i>{" "}
                                - Steven
                            </p>
                        </Center>
                    </ContentTile>
                    <ContentTile grid={50}>
                        <Center>
                            <p style={pStyle}>
                                <i>
                                    "...I'm a fussy listener and also quite
                                    picky about the look and design of speakers.
                                    DNTAcoustics were able to understand my
                                    audio taste and needs. I'm extremely happy
                                    with my new bookshelf speakers. The look and
                                    the sound is exactly what I had in mind.
                                    Highly recommended"
                                </i>{" "}
                                - Fabio
                            </p>
                        </Center>
                    </ContentTile>
                </ContentRow>
                <Spacer size={50} />
            </Section>
            <FooterInfoWrapper>
                <CentreGrouping style={{ minHeight: 150 }}>
                    <ContentTile
                        grid={50}
                        br={{ color: "#444" }}
                        stack={true}
                        align="center"
                    >
                        <Icon name="mail" alt="Email icon" />
                        <Link href="mailto:info@dntacoustics.com.au">
                            info@dntacoustics.com.au
                        </Link>
                    </ContentTile>
                    <ContentTile grid={50} stack={true} align="center">
                        <strong>DNT ACOUSTICS</strong>
                        <span>ABN 77 159 209 974</span>
                        <span>www.dntacoustics.com.au</span>
                    </ContentTile>
                </CentreGrouping>
            </FooterInfoWrapper>
        </PageWrapper>
    );
};

export default HomePage;
