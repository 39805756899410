import styled from "styled-components";

const PageWrapper = styled.div``;

interface SectionProps {
    backgroundColor?: string;
    fontColor?: string;
    borderless?: boolean;
}
const Section = styled.div<SectionProps>`
    display: block;
    ${({ backgroundColor, fontColor, borderless }) => {
        let style: string = "";

        backgroundColor && (style = `background-color: ${backgroundColor};`);
        fontColor && (style += `color: ${fontColor};`);
        !borderless && (style += "padding: 20px;");

        return style;
    }}
`;

interface ContentTileProps {
    ratio?: number;
    grid?: number;
    stack?: boolean;
    br?: { color: string };
    align?: "flex-start" | "center" | "flex-end" | "baseline";
    justify?: "flex-start" | "center" | "flex-end";
}
const ContentTile = styled.div<ContentTileProps>`
    ${({ ratio, grid, stack, br, align, justify }) => {
        !align ? (align = "center") : (align = align);
        !justify ? (justify = "flex-start") : (justify = justify);
        let style: string = "";
        if (!grid) {
            style = `flex-grow: ${ratio ? ratio : "1"};`;
        }
        style = `width: ${grid}%;`;

        if (br && br.color) {
            style += `border-right: 1px solid ${br.color};`;
        }

        // if (stack) {
        style += `display: flex; flex-direction: column; align-items: ${align};justify-content: ${justify};`;
        // }

        return style;
    }}
`;

interface ContentRowProps {
    align?: "flex-start" | "center" | "flex-end" | "baseline";
}
const ContentRow = styled.div<ContentRowProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: ${({ align }) => (align ? align : "center")};

    @media (max-width: 500px) {
        flex-direction: column;

        & ${ContentTile} {
            width: 100%;
            margin-bottom: 20px;
            border-right: none;
        }
    }
`;

const Center = styled.div`
    text-align: center;
`;

const Image = styled.img`
    & svg {
        path: #eee;
        fill: #eee;
    }
`;

interface SpacerProps {
    size?: number;
}
const Spacer = styled.div<SpacerProps>`
    display: block;
    margin-bottom: ${({ size }) => (size ? size : 20)}px;
`;

export { PageWrapper, Section, Center, ContentRow, ContentTile, Image, Spacer };
