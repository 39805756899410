import React from "react";

export function NotFound({ ...props }) {
    return (
        <svg
            viewBox="18.472 22.127 526.396 347.196"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g transform="matrix(1, 0, 0, 1, -68.32972, -84.273323)">
                <path d="m109.2 106.4c-12.121 0-22.398 10.281-22.398 22.398v302.4c0 12.117 10.281 22.398 22.398 22.398h481.6c12.121 0 22.398-10.281 22.398-22.398v-302.4c0-12.121-10.281-22.398-22.398-22.398zm32.375 33.602h416.85l-208.43 184.62zm-21.176 26.074 116.02 102.9-116.02 124.77v-227.68zm459.2 0v227.68l-116.02-124.77 116.02-102.9zm-317.98 125.12 77.176 68.426 0.003906-0.003906c3.0781 2.7578 7.0664 4.2773 11.199 4.2773s8.1211-1.5195 11.199-4.2773l77.176-68.426 119.88 128.8h-416.5l119.88-128.8z" />
            </g>
        </svg>
    );
}
