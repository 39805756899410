import * as Images from "../../images";

import React from "react";

interface ImageProps {
    name: string;
    alt: string;
    [name: string]: any;
}

const Image: React.FC<ImageProps> = ({ name, alt, ...props }): JSX.Element => {
    const targetImage: string = (Images as any)[name];
    return <img src={targetImage} alt={alt} {...{ ...props }} />;
};

export default Image;
